.addActivityAccordionDates {
  flex-direction: column !important;
}

.addActivityAccordionDates > * {
  flex: 1 1 100%;
  width: 100%;
}

.addActivityAccordionDates > *:not(:last-child) {
  margin-bottom: 20px;
}

.ldr-acc-head-icons > button {
  margin: 0 15px 0 0 !important;
}

.topButtons > *:not(:last-child) {
  margin-right: 1rem;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .topSection {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }

  .topSection > * {
    width: 100%;
  }

  .topSection > h3 {
    margin-bottom: 10px !important;
  }

  .topButtons {
    display: flex !important;
    flex-direction: column !important;
  }

  .topButtons > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  /* .accDiv {
    display: flex !important;
    flex-direction: column !important;
  } */

  .accDiv > span {
    display: flex !important;
    flex-direction: column !important;
  }

  .accDiv > span > img {
    width: 140px;
    height: 140px;
    object-fit: contain;
    object-position: center;
    /* border: 1px solid #eee; */
  }

  .accHeadTime {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .accHeadTime > * {
    width: 100%;
  }

  .addActivityAccordionTime > form > div {
    flex-direction: column !important;
  }

  .addActivityAccordionTime > form > div > * {
    width: 100% !important;
  }

  .addActivityAccordionTime > form > div > *:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .accordianSummaryContainer {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }

  .ldr-acc-head {
    flex-direction: column !important;
  }
}

/* Performance Units */
.performanceUnit {
  font-size: 12px;
  color: #5335ca;
}

/* Accordions */
.MuiPaper-root::before {
  height: 0 !important;
}

/* Learner Total Performance Cards Container */
.learnerPerformanceBox {
  display: flex !important;
  /* justify-content: center !important; */
  /* align-items: center !important; */
}

.learnerPerformanceBox > * {
  padding: 8px 2px !important;
  margin: 5px !important;
  min-height: 100% !important;
  text-align: center !important;
  /* border: 1px solid #eee !important; */
  border-radius: 4px !important;
  max-width: calc(25% - 10px) !important;
}

@media only screen and (max-width: 900px) {
  .learnerPerformanceBox > * {
    flex: 1 1 100% !important;
    max-width: 100% !important;
    margin: 5px 0 !important;
  }
}

/* Heading Container */
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerContainer > *:first-child {
    margin-bottom: 1rem;
  }
}

/* Filter Container */
.filterContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-left: 15%;
}

.filterContainer > *:first-child {
  margin-right: 0.5rem;
  width: 100%;
  max-width: 380px;
}

@media only screen and (max-width: 1100px) {
  .filterContainer {
    padding-left: 5%;
  }
}

@media only screen and (max-width: 768px) {
  .filterContainer {
    padding-left: unset;
  }

  .filterContainer > *:first-child {
    max-width: unset;
  }
}
